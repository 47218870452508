import React, { Component } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import '../../App.css';
import { reactAI } from "react-appinsights";
import { 
  createScope,
  partnerCenterTheme,
  menu,
  menuItem,
  dropdown,
  button,
  select,
  option,
  accordion,
  accordionItem,
  datePicker
} from '@harmony/enablers/react';
// import uuid from 'react-native-uuid';
// import { fetchToken } from '../../Util.js';
// import { SERVICE_NAME, SERVICE_OFFERING, COMPONENT_NAME, SERVICE_LINE, COMPONENT_ID } from "../../common/Constants/Constants";
import { appInsights } from '../../common/AppInsights/AppInsights';


// Create a Harmony Enabler scope
const scope = createScope({
  rootElement: document.getElementById('root'),
  basePath: '/OutageCalendar/public/enablers',
  theme: partnerCenterTheme,
  reactInstance: React,
  });

// Generate React components
const Menu = scope.forReact(menu);
const MenuItem = scope.forReact(menuItem);
const Dropdown = scope.forReact(dropdown);
const Button = scope.forReact(button);
const Select = scope.forReact(select);
const Option = scope.forReact(option);
const Accordion = scope.forReact(accordion);
const AccordionItem = scope.forReact(accordionItem);
const DatePicker = scope.forReact(datePicker);
const url = scope.makePath('icons/chevronright.svg');

// sample hard coded values for date picker
let minDate = '01/01/2023'
let maxDate = '07/01/2023'

// filter functionality
let filter = '';
let newfilter ='';
let filtersApplied = []
let  filterEventType = ''

// sets default filter to week
let getDateRange = ['month']
let getEventType = []

let val1 = ''
let val2 = ''

class Filters extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      events: this.props.events,
      filteredevents: this.props.filteredevents,
      newfilteredevents: this.props.newfilteredevents|| [],
      eventsfilter: this.props.events,
      calendars:this.props.calendars || [],
      calendartypes: this.props.calendartypes || [],
      calendarfulllist: [],
      filter: "",
      userOrgName:this.props.userOrgName,
      otherChecked: false,
      role: this.props.role,
      userEmail: '',
      data: JSON.parse(props.filters),
      filterEventType:Dropdown,
      filterDateRange:Dropdown,
      selectedFilter: MenuItem,
      selectedCalendar: 'SCE Calendar',
      isOpen: false,
    };
    this.close = this.close.bind(this);
    

    this.filterEventTypeRef = React.createRef();
    this.filterDateRangeRef = React.createRef();
    this.filterEventType = document.querySelector('#filterEventType');
    this.filterDateRange = document.querySelector('#filterDateRange');
  };
  appInsight = reactAI.appInsights;
  close = () => this.setState({ isOpen: false });


  async componentDidMount() {
    // set the flag to true
    this._isMounted = true;

    getEventType =[];
    getDateRange = ['month'];

    const defaultCalendar = this.state.calendars.find(calendar => calendar.calendarName === 'SCE Calendars');
    if(defaultCalendar){
      await this.onNewFilterChange(defaultCalendar.id, defaultCalendar.calendarName);
    }

    // Using Get data for Big calendar to render it properly
    if (this.props.getData) {
      await this.props.getData();
    }

    setTimeout( () => {
      if(this._isMounted) {
      this.setState({ isOpen: true });
      }
    }, 3000);
  }
  
  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.calendartypes !== this.props.calendartypes) {
      this.setState({ calendartypes: this.props.calendartypes });
    }
  }

  onCalendarChange = async (str1, str2) => {
    this.setState({
      checked: str1, selectedValue: str2.target.value
    });
    // console.log(str1 + " - " + str2.target.value)

    await this.props.newevents(str2.target.value, this.props.regionfilter, this.props.timezonefilter,);
  }

  onNewFilterChange = async (str1, str2) => {
    this.setState({
      checked: parseInt(str1) - 1,
      selectedValue: str2
    });
    //console.log("Event happening")
    //console.log('this is the calendar change')
    //console.log(str1 + " - " + str2)
    // console.log('this is the onNewFilterChange in filter.js')
    // console.log('STR2: '+str2)
    // console.log('REGION FILTER: '+this.props.timezonefilter,)
    // console.log('TIMEZONE FILTER: '+this.props.timezonefilter,)
    // console.log('GETSELECTEDDATE: '+getSelectedDateFilter())

    val1 = str1
    val2 = str2

    await this.props.newevents(str2, this.props.timezonefilter, this.props.timezonefilter, getSelectedDateFilter(), getSelectedEventFilter());
  }

  handleChange = event => {
    this.setState({ filter: event.target.value });
  };

  toggleCheckbox(index) {
    debugger;
    const { checkboxes } = this.state;
    checkboxes[index].checked = !checkboxes[index].checked;
    this.setState({
      checkboxes
    });
  }

  changeDate = async (newdate) => {
    this.setState({ date: newdate });
    await this.props.newdate(newdate);
  }

  // filter functionality
  filterPaneSearch(){
    var eventTypes =[];
    filtersApplied.length=0;
    // console.log('filterpaneseach call working')
    // console.log('array: '+ eventTypes);

    this.filterEventType.querySelectorAll('menuET').forEach(item => {
      this.selectedFilter = <MenuItem>{item}</MenuItem>;
      if(this.selectedFilter.checked)
      eventTypes.push(this.selectedFilter.attributes.getNamedItem('value').nodeValue);
      // console.log('events type array: '+ eventTypes);
    });
  }

  getSelectedDateFilter(){
    var daterange = []
    filtersApplied.length = 0
    // console.log('this is filterPaneDateRange')
    // console.log('this is daterange array: '+daterange)
    // console.log('this is daterange array: '+daterange.length)

    this.filterDateRangeRef.current.querySelectorAll('.datefilteroption').forEach(item => {
      if (item.checked)
      //daterange = []
      daterange.push(item.attributes.getNamedItem('value').nodeValue);
    });
    getDateRange = daterange
    //return daterange
    // console.log('this is updated daterange array: '+daterange)
    // console.log('this is updated daterange array: '+daterange.length)

    // Add event listener to menu items
this.filterDateRangeRef.current.querySelectorAll('.datefilteroption').forEach(item => {
  item.addEventListener('click', () => {
    // Loop through all menu items
    this.filterDateRangeRef.current.querySelectorAll('.datefilteroption').forEach(otherItem => {
      // Uncheck any that are currently checked, except for the one that was just clicked
      if (otherItem !== item) {
        otherItem.checked = false;
      }
    });
  });
});


  }


  getSelectedEventFilter(){
    var eventType = []
    filtersApplied.length = 0
    // console.log('this is getSelectedEventFilter function')
    // console.log('this is eventType array: '+eventType)
    // console.log('this is eventType array length: '+eventType.length)

    this.filterEventTypeRef.current.querySelectorAll('.eventfilteroption').forEach(item => {
      if (item.checked)
        // eventType.push(item.attributes.getNamedItem('value').nodeValue);
        eventType.push(parseInt(item.attributes.getNamedItem('value').nodeValue));
    });
    getEventType = eventType

    // console.log('this is updated eventType array: '+eventType)
    // console.log('this is updated eventType array length: '+eventType.length)

    this.filterEventTypeRef.current.querySelectorAll('.eventfilteroption').forEach(item => {
      item.addEventListener('click', () => {
        // Loop through all menu items
        this.filterEventTypeRef.current.querySelectorAll('.eventfilteroption').forEach(otherItem => {
          // Uncheck any that are currently checked, except for the one that was just clicked
          if (otherItem !== item) {
            otherItem.checked = false;
          }
        });
      });
    });



  }


  async filterPaneDateRange(){
    const daterange = []
    filtersApplied.length = 0

    // console.log('this is filterPaneDateRange')
    // console.log('this is daterange array: '+daterange)
    // console.log('this is daterange array: '+daterange.length)

    this.filterDateRangeRef.current.querySelectorAll('.datefilteroption').forEach(item => {
      if (item.checked)
      daterange.push(item.attributes.getNamedItem('value').nodeValue);
    });

    // console.log('this is updated daterange array: '+daterange)
    // console.log('this is updated daterange array: '+daterange.length)

    if (daterange.length > 0) {
      filtersApplied.push("Date Range");

      const today = new Date();
      const startOfWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay());
      const endOfWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() + (6 - today.getDay()));

      const filteredEvents = this.state.filteredevents.filter(event => {
        const startDate = new Date(event.start);

        if (daterange.indexOf('day') !== -1) {
          return startDate.getFullYear() === today.getFullYear() &&
            startDate.getMonth() === today.getMonth() &&
            startDate.getDate() === today.getDate();
        } else if (daterange.indexOf('week') !== -1) {
          return startDate >= startOfWeek && startDate <= endOfWeek;
        } else if (daterange.indexOf('month') !== -1) {
          return (
            startDate.getFullYear() === today.getFullYear() &&
            startDate.getMonth() === today.getMonth()
          );
        }
        return false;
      });

      this.setState({ newfilteredevents: filteredEvents });
    }
  }

  filterPaneSearch2() {
    var eventTypes = [];
    var filterevents = [];

    this.filterEventTypeRef.current.querySelectorAll('.menuET').forEach(item => {
      if (item.checked)
        eventTypes.push(item.attributes.getNamedItem('value').nodeValue);
    });

      // filter by event type
      if(eventTypes.length > 0) {
        filtersApplied.push("Event Type")
        // console.log("filters applied array: "+filtersApplied)
        // console.log("events lenth: "+ this.state.filteredevents.length)
        // console.log("this is eventTypes array: "+eventTypes)
        this.setState.filteredevents = this.state.filteredevents.filter(row => eventTypes.includes(row.status));
        // console.log(this.state.filteredevents)
      }
  }

  handleFilter = () => {

    getEventType =[];
    getDateRange = [];

    this.getSelectedDateFilter();
    this.getSelectedEventFilter();

    if(getDateRange.length === 0 && getEventType.length === 0) {
      return;
    }

    this.onNewFilterChange(this.state.checked, this.state.selectedValue);
  }


  clearFilters() {
    this.filterEventTypeRef.current.querySelectorAll('.eventfilteroption').forEach(item => {
      item.checked = false;
    });
    getEventType =[];
    this.filterDateRangeRef.current.querySelectorAll('.datefilteroption').forEach(item => {
      item.checked = false;
    });
    getDateRange = ['month'];

    this.onNewFilterChange(this.state.checked, this.state.selectedValue);
  }


  filteredCalendarData(calendarType) {
    const { data, filter } = this.state;
    const lowercasedFilter = filter.toLowerCase();
    return data[calendarType] ? data[calendarType].filter(item => {
      return Object.keys(item).some(key =>
        typeof item[key] === 'string' && item[key].toLowerCase().includes(lowercasedFilter)
      );
    }) : [];
  }

  render() {

    var isinternalUsers = (this.props.userEmail.toLowerCase().includes("microsoft.com") ? true : false); 
    var partnerOrgName="";
    if(isinternalUsers===false)
    {
      partnerOrgName=this.props.userOrgName;
    }

    const { filter, data, selectedValue } = this.state;
    const lowercasedFilter = filter.toLowerCase();
    const calendarData = data["MyCalendar"].filter(item => {
      return Object.keys(item).some(key =>
        item[key].toLowerCase().includes(lowercasedFilter)
      );
    });
    const otherCalendarData = data["OtherCalendar"].filter(item => {
      return Object.keys(item).some(key =>
        item[key].toLowerCase().includes(lowercasedFilter)
      );
    });
    const holidayCalendarData = data["HolidayCalendar"].filter(item => {
      return Object.keys(item).some(key =>
        item[key].toLowerCase().includes(lowercasedFilter)
      );
    });
    const factoryproductionCalendarData = data["FactoryProductionCalendar"].filter(item => {
      return Object.keys(item).some(key =>
        item[key].toLowerCase().includes(lowercasedFilter)
      );
    });

    


    // const { calendartypes, calendars } = this.state;

    // console.log('Rendering filter components');
    // console.log('Calendar Types:', calendartypes);
    // console.log('Calendars:', calendars);

    return (
      <div className="external-events-container" id="external-events">
        <div className="filters">
          <Accordion>
            <AccordionItem>
              <h4 slot="heading" >
                Filters
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 2048 2048">
                  <path d="M0 320q0-40 15-75t41-61 61-41 75-15h1664q40 0 75 15t61 41 41 61 15 75q0 82-60 139l-648 618q-14 14-25 30t-19 33q-16 35-16 76v768q0 26-19 45t-45 19q-19 0-35-11l-384-256q-29-19-29-53v-512q0-40-15-76-8-18-19-33t-26-30L60 459Q0 402 0 320zm1920-1q0-26-19-44t-45-19H192q-26 0-45 18t-19 45q0 29 20 47l649 618q47 45 73 106t26 126v478l256 170v-648q0-65 26-126t73-106l649-618q20-18 20-47z" />
                </svg>
              </h4>

              <Dropdown id="filterDateRange" ref={this.filterDateRangeRef}>
                <Button slot="trigger" style={{ width: '200px' }}  caret>Date Range</Button>
                <Menu style={{ width: '200px' }} >
                    <MenuItem className="datefilteroption" role="menuitemcheckbox" value="day">Events today</MenuItem>
                    <MenuItem className="datefilteroption" role="menuitemcheckbox" value="week">Events this week</MenuItem>
                    <MenuItem className="datefilteroption" role="menuitemcheckbox" value="month">Events this month</MenuItem>
                    <MenuItem className="datefilteroption" role="menuitemcheckbox" value="next-week">Events next week</MenuItem>
                    <MenuItem className="datefilteroption" role="menuitemcheckbox" value="next-month">Events next month</MenuItem>
                    <MenuItem className="datefilteroption" role="menuitemcheckbox" value="previous-month">Events previous month</MenuItem>
                </Menu>
              </Dropdown>
              <br/>
              <br/>
              <Dropdown id="filterEventType" ref={this.filterEventTypeRef}>
                <Button slot="trigger" style={{ width: '200px' }}  caret>Event Type</Button>
                <Menu style={{ width: '200px' }} >
                  <MenuItem className="eventfilteroption" role="menuitemcheckbox" value="3">Approved</MenuItem>
                  <MenuItem className="eventfilteroption" role="menuitemcheckbox" value="1">Pending Review</MenuItem>
                </Menu>
              </Dropdown>
              <div style={{ display: 'inline-block', marginRight: '10px' }}>
                <div className="filterfooter" style={{ marginTop: '30px' }}>
                  <Button appearance="primary" onClick={()=>{this.handleFilter();}}>Filter</Button>
                </div>
              </div>

              <div style={{ display: 'inline-block' }}>
                <div className="filterfooter" style={{ marginTop: '30px' }}>
                  <Button appearance="primary" onClick={()=>{this.clearFilters(); }}>Clear Filters</Button>
                </div>
              </div>
            </AccordionItem>
          </Accordion>
        </div>

        <div className="calendar-filters" style={{ marginTop: '20px', marginLeft: '35px' }}>
          <h4 style={{ marginBottom: '20px' }}>SAMP Calendars </h4>
        </div>
          {this.state.calendartypes.map((types) => (
            <div key={types.id}>
          <Accordion>
                  <AccordionItem>
                  <span slot="heading"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 2048 2048">
            <path d="M256 704q0-26 19-45t45-19q26 0 45 19l659 658 659-658q19-19 45-19t45 19 19 45q0 26-19 45l-704 704q-19 19-45 19t-45-19L275 749q-19-19-19-45z" />
            </svg>     {types.calendarTypeName} </span>

                    {this.state.calendars.filter((calendar) => {
                      if (types.id === calendar.calendarTypeId) {

                        if (this.props.userEmail.toLowerCase().includes("microsoft.com")) {
                          // Internal user, grant access to all calendars
                        
                          // testing purposes
                          // console.log('user email: ' + this.props.userEmail);
                          // console.log('calendar organisation: '+calendar.organization)
                          // console.log('user organisation: '+ this.props.userOrgName)
                          // console.log("user is internal")

                          return true;
                        } else {
                          // External user, grant access based on organization
                          // console.log("user is external")
                          return calendar.organization.toLowerCase() === this.props.userOrgName.toLowerCase();
                        }
                      }
                      return false;
                    }).map((calendar) => (
                      // each calendar that can be selected
                      <p key={calendar.id}>
                        <Option  value={calendar.id} onHeSelected={this.onNewFilterChange.bind(this, calendar.id, calendar.calendarName,)} style={{marginLeft: '35px' }}>
                          {calendar.calendarName}
                        </Option>
                      </p>
                    ))}
                  </AccordionItem>
                </Accordion>

            </div>
          ))}
      </div>
    );
  }
}

function getSelectedDateFilter(){
  return getDateRange
}

function getSelectedEventFilter(){
  return getEventType
}

export{ getSelectedDateFilter, getSelectedEventFilter }
export default Filters;