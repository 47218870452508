import React from 'react';
import "react-big-calendar/lib/css/react-big-calendar.css";
import '../../App.css'
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import CalendarApp from "../../components/Calendar/CalendarApp";
import { Calendar, momentLocalizer} from "react-big-calendar";
import moment from 'moment';
import Toolbar from 'react-big-calendar/lib/Toolbar';
import "react-big-calendar/lib/css/react-big-calendar.css";
import * as Datetime from 'react-datetime';
import '../../App.css'
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import {Modal,Navbar,Form,NavDropdown,Nav,FormControl, Container, Row,Col,ButtonGroup} from 'react-bootstrap';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import format from "date-fns/format";
import RadioButtons from '../RadioButton/RadioButtons';
import { UserAgentApplication } from 'msal';
import config from '../../config';
import { AccessTokenValue } from 'msal/lib-commonjs/cache/AccessTokenValue';
import { th } from 'date-fns/locale';
import Dropdown from '../ServiceDropdown/DropDown';
import ReactDropDown from '../ServiceDropdown/DropDown';
import {fetchToken} from '../../Util.js';
import ReactRegionDropDown from '../RegionDropdown/Region';
import ReactEditDropDown from '../ServiceDropdown/EditDropDown';
import { Radio, RadioGroup} from 'react-radio-group';
import ReactEditRegionDropDown from '../RegionDropdown/EditRegion';
import CustomSpinner from "../../common/Spinner/Spinner";
import AlertDialog from "../../common/AlertDialog/AlertDialog";
import AddEventModalPopUp from "../../components/AddEvent/AddEvent";
import EditModalPopUp from "../../components/EditEvent/EditEvent";
import ErrorBoundary from "../../common/ErrorBoundary/ErrorBoundary";
import {SERVICE_NAME,SERVICE_OFFERING,COMPONENT_NAME,SERVICE_LINE,COMPONENT_ID} from "../../common/Constants/Constants";
import { reactAI } from "react-appinsights";
import uuid from 'react-native-uuid';
import AccessDenied from "../../common/AccessDenied/AccessDenied";
import {appInsights} from '../../common/AppInsights/AppInsights';
import alertDialog from '../../common/AlertDialog/AlertDialog';
import {Fabric} from 'office-ui-fabric-react/lib/Fabric'
import {ButtonType,DefaultButton} from 'office-ui-fabric-react/lib/Button'
import {Dialog, DialogType, DialogFooter} from 'office-ui-fabric-react/lib/Dialog'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import InternalServerError from "../../common/ErrorPages/InternalServerError";
import ReactFactoryDropDown from '../FactoryDropDown/Factory';
import {Children} from 'react';
import AddSAPEventModalPopUp from "../../components/AddEvent/AddSAPEvent";
import EditSAPEventModalPopUp from "../../components/EditEvent/EditSAPEvent";
import Filters from './Filters.js';
import {GetPersonaLegend} from './Legends';
import CREWHolidayCalendarFilter from './CREWHolidayCalendarFilter';
import TimezoneCalendarFilter from './TimezoneCalendarFilter';
import AddFactoryProductionEvent from "../../components/AddEvent/AddFactoryProductionEvent";
import EditFactoryProductionEvent from "../../components/EditEvent/EditFactoryProductionEvent";
import { createScope, partnerCenterTheme, button, icon, menu, menuItem, commandBar, pageTitle, dataGrid, text, badge } from '@harmony/enablers/react'; 
import { event } from 'jquery';
import { getSelectedDateFilter } from './Filters.js';
import { fetchData } from '../../Util';

// Create a Harmony Enabler scope
const scope = createScope({
  theme: partnerCenterTheme,
  reactInstance: React,
});

const Button = scope.forReact(button);
const Icon = scope.forReact(icon);
const Menu = scope.forReact(menu);
const MenuItem = scope.forReact(menuItem);
const CommandBar = scope.forReact(commandBar);
const PageTitle = scope.forReact(pageTitle);
const DataGrid = scope.forReact(dataGrid);
const Text = scope.forReact(text);
const Badge = scope.forReact(badge);

moment.locale("en-GB");
const localizer = momentLocalizer(moment);
const OutageCalendar = withDragAndDrop(Calendar);
//const OutageCalendar = ''
// let testparvalue = 'placeholder'

const wrapperStyle = {
  display: 'flex',
  flexWrap: 'wrap',
  gap: 'var(--he-spacing-medium)',
};

const commandBarStyle = {
  flex: '1 1 200px',
};

const searchStyle = {
  flex: '0 1 160px',
};


var ReturnStatus = 200;

class NewToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      role: props.currrole
    };
  }

  render() {
    return (
      (this.state.role !== "Reader") ?
        <button type="button" aria-labelledby="newrequest" className="btn btn-primary" onClick={() => { this.props.handleAddEventModal(true) }}>New Request</button>
        : null
    );
  }
}

export class ReactCalendar extends React.Component
{

  constructor(props){
    super(props);
    this.state = {
      events:[],
      filteredevents:[],
      displayedDateRage: {},
      calendarevents:[],
      newfilteredevents:[],
      emptyArray:true,current_view: 'month',current_date: moment.utc().toDate().toUTCString(),
      myDate : (this.props.date === undefined) ? new Date() : this.props.date.myProp,
      start : (this.props.date === undefined) ? new Date() : this.props.date.start,
      filters : this.props.filters,
      role:this.props.role,
      calendars:this.props.calendars,
      calendartypes: this.props.calendartypes,
      event:{},
      selectedRadioButton:'',
      selectedRegionData:'',
      selectedCalendar:'SCE Calendar',
      selectedTimezoneData:"",
      userOrgName:this.props.userOrgName,
      data:[],
      end : (this.props.date === undefined) ? new Date() : this.props.date.end,
      testparvalue: 'placeholder',
    }
    this.bindScopes([
      'onView',
      'onNavigate',
      'updateTimes',
      'handleNavigate',
      'timesToEvents'
    ]);
  }

  async componentDidMount() {
    ReactCalendar.instance=this;
    this.updateTimes();
    await this.GetData();
    // Filter events based on the default calendar
    await this.updateEvents(this.state.selectedCalendar, this.state.selectedRegionData, this.state.selectedTimezoneData, this.state.getDateRange, this.state.getEventType);
    await this.updateEvents("SCE Calendars");
  }

  // Can be use at later stage as we are using the console right now so commented it.
  async componentDidUpdate(prevProps, prevState) {
    if (prevState.calendars !== this.state.calendars || prevState.calendartypes !== this.state.calendartypes) {
      // console.log('Calendars or Calendar Types updated');
    }
  }

  async updateSmallCalendar(value){
    this.setState({myDate:value});
  }

  bindScopes(keys){
    for(let key of keys){
      this[key] = this[key].bind(this);
    }
  }

  handleNavigate(date, view, action) {
    this.setState({myDate: moment(date).toDate()});
}

  timesToEvents(times){
    const events = times.map(time => {
      const start_date = moment(time.Date);
      const end_date = start_date.clone().add(time.Minutes, 'minutes');
      return {
        title: time.Incident.lookupName,
        start: start_date.toDate(),
        end:   end_date.toDate()
      };
    })

    this.setState({
      events: events
    })
  }

  onView(view){
    this.setState({
      current_view: view
    });

    this.updateTimes(this.state.current_date, 'day');
  }
  onNavigate(date, view){
    const new_date = moment(date);
    this.setState({
      current_date: new_date
    });

    this.updateTimes(new_date, view);
  }

  updateTimes(date = this.state.current_date, view = this.state.current_view){
    let start, end;
    // if view is day: from moment(date).startOf('day') to moment(date).endOf('day');
    if(view === 'day'){
      start = moment(date).startOf('day');
      end   = moment(date).endOf('day');
    }
    else if(view === 'week'){
      start = moment(date).startOf('week');
      end   = moment(date).endOf('week');
    }

    else if(view === 'month'){
      end   = moment(date).endOf('month');
      //.add(7, 'days');
      start = moment(date).startOf('month');
      //.subtract(7, 'days');

    }
    // if view is agenda: from moment(date).startOf('day') to moment(date).endOf('day').add(1, 'month');
    else if(view === 'agenda'){
      start = moment(date).startOf('day');
      end   = moment(date).endOf('day').add(1, 'month');
    }
    this.setState({start:start.toString(), end:end.toString()})
 }

  RemoveEvent = (outageId) => {

    let prevEvents = this.state.events;
    let currentEvents = prevEvents.filter((event)=>{return event.outageId !== outageId}).map((event)=> event)
    this.setState({events:currentEvents});
    this.props.closeModalPopUpFunc(false);
  }

  AddEvent = (event) => {

    let prevEvents = this.state.events;
    prevEvents.push(event)
    this.setState({events:prevEvents});
    this.props.handleAddEventModalPopUpFunc(false);
  }

  UpdateEvent = (event) => {

    let prevEvents = this.state.events;
    let currentEvents = prevEvents.findIndex((newevent)=>{return newevent.outageId === event.EventId})
    prevEvents[currentEvents] = event;
    this.setState({events:prevEvents});
    this.props.closeModalPopUpFunc(false);
  }

  eventPropGetter = (event, start, end, isSelected) =>
  {

    // EVENT COLOURS ON CALENDAR
    let newStyle = {
      borderRadius: "0px",
      border: "none",
      role:"button"
    };

    if(event.outageType === "Informational BusinessUser SAP ReleaseEvent") {
        if(event.category === "Finance Blackout") {
            newStyle.backgroundColor = "#de2710"
        }
        else if (event.category === 'Public Holiday') {
            newStyle.backgroundColor = "#af5f00"
        }
        else if (event.category === 'Month/Quarter End Restriction') {
          newStyle.backgroundColor = "darkgreen"
        }
        else if (event.category === 'Public Holiday') {
          newStyle.backgroundColor = "#af5f00"
        }
    }
    else if (event.category === 'Public Holiday')
    {
      newStyle.backgroundColor = "#af5f00"
    }
    else if(event.category === 'Plant Holiday')
    {
      newStyle.backgroundColor = "darkblue"
    }
    else if(event.category === 'OT')
    {
      newStyle.backgroundColor = "#007ba6"
    }
    else if(event.category === 'Holiday')
    {
      newStyle.backgroundColor = "black"
    }
    else if(event.category === 'Physical Inventory Count')
    {
      newStyle.backgroundColor = "#af5f00"
    }
    else if(event.category === 'Production')
    {
      newStyle.backgroundColor = "green"
    }
    else if(event.category === 'Partial Production')
    {
      newStyle.backgroundColor = "purple"
    }
    else
    {
      if (event.status === 1 || event.status === 2){
        newStyle.backgroundColor = "#49498D"
      }
      else if(event.status === 3){
        newStyle.backgroundColor = "green"
      }
    }

    return {
      style: newStyle
    };
}


  eventStyleGetter = (event, start, end, isSelected) => {

    var backgroundColor = '#' + event.hexColor;
    var style = {
        backgroundColor: '#de2710',
        borderRadius: '0px',
        opacity: 0.8,
        color: 'black',
        border: '0px',
        display: 'block'
    };

    return {
        style: style
    };
  }

  // New code for GetData function
  async GetData() {
    appInsights.trackTrace(
      { message: 'Calendar Component Loaded and trying to fetch events.' },
      {
        "userName": this.props.userEmail,
        "ComponentId" : COMPONENT_ID,
        "ComponentName" : COMPONENT_NAME,
        "EnvironmentName" : window.env.ENVIRONMENT,
        "ServiceLineName" : SERVICE_LINE,
        "ServiceName" : SERVICE_NAME,
        "ServiceOfferingName" : SERVICE_OFFERING,
        "Correlation Id": uuid.v1(),
        "Component": 'Calendar Page',
      }
    );

    const token = await fetchToken();
    const correlationId = uuid.v1();
    const bearer ='Bearer ' + token;
    const headers = {
      'authorization': bearer,
      'CorrelationId': correlationId,
      'Accept' : 'application/json',
      'Content-Type': 'application/json'
    };

    this._isMounted = true;
    this.setState({ isLoading: true });

    try {
      const outageUrl = window.env.APIMURL + "/api/outage/getoutages";
      const calendarUrl = window.env.APIMURL + "/api/outage/getcalendars";
      const calendarTypesUrl = window.env.APIMURL + "/api/outage/getcalendartypes";

      const [outageRes, calendarRes, calendarTypesRes] = await Promise.all([
        fetchData(outageUrl, headers, "Outages"),
        fetchData(calendarUrl, headers, "Calendars"),
        fetchData(calendarTypesUrl, headers, "Calendar Types")
      ]);

      const returnLogicLoop = {
        "Informational BusinessUser SAP ReleaseEvent": (event) => ({
          outageId:event.eventId,
          outageTitle:event.title,
          organization:event.organization,
          outageDescription:event.description,
          category:event.category,
          outageType:event.outageType,
          location:event.location,
          allDay: event.allDayEvent,
          recurrence: event.recurrence,
          end: new Date(event.endTime),
          start: new Date(event.startTime),
          nacategory:event.naCategory,
          title:event.title,
          Timezone:event.timezone
        }),
        "CREW Holiday Calendar": (event) => ({
          outageId:event.eventId,
          holidayName:event.title,
          organization:event.organization,
          engContact:event.engContact,
          extContact:event.externalContact,
          outageType:event.outageType,
          region:event.region,
          country:event.country,
          end: new Date(event.endTime),
          start: new Date(event.startTime),
          title:event.title + " - " + event.region + " - " + event.country,
          status:event.approvalStatus,
          plantName:event.factoryName,
          holidayType:event.category,
          category:event.category,
          nacategory:event.naCategory,
          Timezone:event.timezone
        })
      };

      const defaultLogic = (event) => ({
        outageId:event.eventId,
        outageTitle:event.title,
        organization:event.organization,
        outageDescription:event.description,
        outageImpact:event.impact,
        pmContact:event.pmcontact,
        engContact:event.engContact,
        extContact:event.externalContact,
        outageType:event.outageType,
        region:event.region,
        country:event.country,
        teamgroup:event.teamGroup,
        service:event.service,
        component:event.component,
        allDay: event.allDayEvent,
        end: new Date(event.endTime),
        start: new Date(event.startTime),
        title:event.title,
        status:event.approvalStatus,
        servicegroup:event.serviceGroup,
        factory:event.factoryName,
        division:event.division,
        orgName:event.orgName,
        eventstatus:event.eventStatus,
        nextsteps:event.nextSteps,
        nacategory:event.naCategory,
        incidentNumber:event.incidentNumber,
        Timezone:event.timezone,
        severity:event.severity,
        bridgeurls:event.bridgeUrls,
        updatedata:event.updateData,
        resolutiondata:event.resolutionData,
        timelinedata:event.timelineData,
        category:event.category,
      });

      const processedOutages = Array.isArray(outageRes.data) ? outageRes.data.map((event) => {
        const logic =
          returnLogicLoop[event.outageType] ||
          returnLogicLoop[event.naCategory] ||
          defaultLogic;
        return logic(event);
      }) : [];

      // adding filter for events for current month
      const currentDate = new Date();
      const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

      const currentMonthEvents = processedOutages.filter(event => {
        const eventDate = new Date(event.start);
        return eventDate >= startOfMonth && eventDate <= endOfMonth;
      });

      // console.log("current month events:", currentMonthEvents);
      const updatedEvents=this.setEventDataUTCTimezone(processedOutages);

      const calendars = Array.isArray(calendarRes.data) ? calendarRes.data : [];
      const calendartypes = Array.isArray(calendarTypesRes.data) ? calendarTypesRes.data : [];

      const defaultEvents = (
        this.props.role === "BusinessUser SAP"
        ) ?
        updatedEvents.filter(x=>x.nacategory  === "BusinessUser SAP")
        :
        updatedEvents.filter(x=>(
          x.nacategory !== "BusinessUser SAP" &&
          x.nacategory!=="CREW Holiday Calendar" &&
          x.nacategory!=="JDM1 Production Calendar"
        )
      );

      if(this._isMounted){
        this.setState({
          events: defaultEvents,
          filteredevents:  updatedEvents,
          calendarevents: processedOutages,
          calendars: calendars,
          calendartypes: calendartypes,
          emptyArray: defaultEvents.length === 0,
          isLoading: false,
          ReturnStatus: outageRes.statusCode
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      this.setState({
        isLoading: false,
        showErrorDialog: true,
        errorMessage: "An error occurred while getting calendar events. Please try again later."
      });
    }

    this.props.setClick(this.RemoveEvent);
    this.props.AddsetClick(this.AddEvent);
    this.props.UpdatesetClick(this.UpdateEvent);
  }

  onEventResize = (type, { event, start, end, allDay }) => {
    this.setState(state => {
      state.events[0].start = start;
      state.events[0].end = end;
      return { events: state.events };
    });
  };

  setCurrentEvent = (event) => {
    this.setState({ event: event });
  }

  onEventDrop = ({ event, start, end, allDay }) => {
    alert(start)
  };

 convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  async updateEvents(
    value, 
    selectedregion = this.state.selectedRegionData, 
    selectedTimezone = this.state.selectedTimezoneData, 
    selectedDateFilter = ['month'], 
    selectedEventFilter = []
  ) {
    // Ensure default values if parameters are undefined
      const region= selectedregion ===''|| selectedregion===undefined|| selectedregion===null?this.state.selectedRegionData:selectedregion;
  
      const timezone=selectedTimezone===''||selectedTimezone===undefined||selectedTimezone===null?this.state.selectedTimezoneData:selectedTimezone;
  
      const prevselectedTimezone=this.state.selectedTimezoneData;
      
      var isinternalUsers = (this.props.userEmail.toLowerCase().includes("microsoft.com") ? true : false); 
      var partnerOrgName="";
  
      let filteredEvents = this.state.calendarevents;
    
      // this.setState({ filteredevents: this.state.calendarevents });
    
      // Create a dictionary of events by category
      const eventsByCategory = {};
      let updatedTestparvalue = this.state.testparvalue;

      filteredEvents.forEach(event => {
        if (event.nacategory === value) {
          event.nacategory = value;
          updatedTestparvalue = event.nacategory;
        }
        if (!eventsByCategory[event.nacategory]) {
          eventsByCategory[event.nacategory] = [];
        }
        eventsByCategory[event.nacategory].push(event);
      });

      this.setState({ testparvalue: updatedTestparvalue });
    
      // Get the events for the given value
      const calevents = eventsByCategory[value] || [];
      // console.log('calevents:', calevents);

      const timezoneConvertedEvents = this.selectedtimezoneCalendarData(calevents, selectedTimezone, prevselectedTimezone);

      const today = new Date();
      let finalFilteredEvents = timezoneConvertedEvents;

      // Filter the new events array based on selected date filter
      if (selectedDateFilter.length > 0) {
        finalFilteredEvents = timezoneConvertedEvents.filter(event => {
          const startDate = new Date(event.start);
  
          if(selectedDateFilter.includes('day')) {
            return startDate.toDateString() === today.toDateString();
          } else if(selectedDateFilter.includes('week')) {
            const startOfWeek = new Date(today);
            startOfWeek.setDate(today.getDate() - today.getDay());
            const endOfWeek = new Date(startOfWeek);
            endOfWeek.setDate(startOfWeek.getDate() + 6);
            return startDate >= startOfWeek && startDate <= endOfWeek;
          } else if(selectedDateFilter.includes("month")){
            return (
              startDate.getFullYear() === today.getFullYear() &&
              startDate.getMonth() === today.getMonth()
            );
          } else if(selectedDateFilter.includes("next-week")){
            const startOfWeek = new Date(today);
            startOfWeek.setDate(today.getDate() + (7 - today.getDay()));
            const endOfWeek = new Date(startOfWeek);
            endOfWeek.setDate(startOfWeek.getDate() + 6);
            return startDate >= startOfWeek && startDate <= endOfWeek;
          } else if(selectedDateFilter.includes("previous-month")){
            const previousMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
            const previousMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);
            return startDate >= previousMonthStart && startDate <= previousMonthEnd;
          } else if(selectedDateFilter.includes("next-month")){
            const nextMonthStart = new Date(today.getFullYear(), today.getMonth() + 1, 1);
            const nextMonthEnd = new Date(today.getFullYear(), today.getMonth() + 2, 0);
            return startDate >= nextMonthStart && startDate <= nextMonthEnd;
          }
          return true;
        });
      }
    
      // Filter the new events array based on selected event filter
      if( selectedEventFilter.length > 0){
        // console.log('this is the IF for event filter inside BigCalendar.js')
        const currentDate = new Date();
        const threeMonthsAgo = new Date();
        threeMonthsAgo.setMonth(currentDate.getMonth() - 3);
  
        finalFilteredEvents = finalFilteredEvents.filter(
          event => selectedEventFilter.includes(event.status) && new Date(event.start) >= threeMonthsAgo
        );
      }

      this.setState({
        events: finalFilteredEvents,
        filteredEvents: finalFilteredEvents,
        selectedRadioButton: value,
        selectedRegionData: region,
        selectedCalendar: value,
        testparvalue: value,
      });
      // console.log("Final Filtered Events:", finalFilteredEvents);
      await this.props.setCalendar(value);
    }

  ColoredDateCellWrapper = ({children, value}) =>
    React.cloneElement(Children.only(children), {
        style: {
            ...children.style,
            //JSON.stringify(value).slice(1,11)
            //toISOString().split('T')[0]
            backgroundColor: this.convert(value) === this.convert(this.state.myDate) ? '#17a2b8' : null, 
        },
    });

     // convert UTC date to CST date .
     convertUTCDateToCST(date)
     {
       var now_utc =  date;
       now_utc.setHours(now_utc.getHours()+8);
       //return now_utc.toUTCString();
       return now_utc;
     }
     // convert UTC date to PST date . 
     convertUTCDateToPST(date)
     {
       var now_utc =  date;
       var isDST=this.checkDateForDaylightSaving(date);
       if (isDST===true)
       {
         now_utc.setHours(now_utc.getHours()-7); //during daylight saving months .
       }
       else
       {
         now_utc.setHours(now_utc.getHours()-8);
       } 
       //return now_utc.toUTCString();
       return now_utc;
     }
     // convert CST date to UTC date .
    convertCSTDateToUTC(date)
    {
      var now_cst =  date;
      now_cst.setHours(now_cst.getHours()-8);
      //return now_cst.toUTCString();
      return now_cst;
    }
    convertCSTDateToPST(date)
    {
      var now_cst =  date;
      var isDST=this.checkDateForDaylightSaving(date);

      if (isDST===true)
      {
      now_cst.setHours(now_cst.getHours()-8);
      now_cst.setHours(now_cst.getHours()-7);
      }
      else
      {
        now_cst.setHours(now_cst.getHours()-8);
        now_cst.setHours(now_cst.getHours()-8);
      }
      //return now_cst.toUTCString();
      return now_cst;
    }
    // convert PST date to UTC date .
    convertPSTDateToUTC(date)
    {
      var now_pst =  date;
      var isDST=this.checkDateForDaylightSaving(date);
      if (isDST===true)
      {
        now_pst.setHours(now_pst.getHours()+7); //during daylight saving months .
      }
      else
      {
        now_pst.setHours(now_pst.getHours()+8);
      } 
      //return now_pst.toUTCString();
      return now_pst;
    }
    convertPSTDateToCST(date)
    {
      var now_pst =  date;
      var isDST=this.checkDateForDaylightSaving(date);

      if (isDST===true)
      {
         now_pst.setHours(now_pst.getHours()+7);
         now_pst.setHours(now_pst.getHours()+8);
      }
      else
      {
        now_pst.setHours(now_pst.getHours()+8);
        now_pst.setHours(now_pst.getHours()+8);
      }
      //return now_cst.toUTCString();
      return now_pst;
    }

    //calculate daylight saving start date , end date  in a year . 
  
    calculateDSTStartDate()
    {
      const days = {
        Mon: 1,
        Tue: 2,
        Wed: 3,
        Thu: 4,
        Fri: 5,
        Sat: 6,
        Sun: 0
      }
     
      var weekNumber,day,monthMoment;
      var year=new Date().getFullYear();
      var marchdate=year+'-03-01';
      var dststartdate;

      // 2nd Sunday of March . 
      weekNumber=2;
      day=days.Sun;
      monthMoment=moment(marchdate);
     
      let m = monthMoment.clone()
         .startOf('month')                     
         .day(day)
        if (m.month() !== monthMoment.month()) 
        {
          m.add(7, 'd');
        }
        //dststartdate= m.add(7 * (weekNumber - 1), 'd').format('YYYY-MM-DDTHH:mm:ss.fff Z');
        dststartdate= m.add(7 * (weekNumber - 1), 'd');
        dststartdate=moment(dststartdate).add(2, 'hours');
        return dststartdate;            
       
    }

    calculateDSTEndDate()
    {
      const days = {
        Mon: 1,
        Tue: 2,
        Wed: 3,
        Thu: 4,
        Fri: 5,
        Sat: 6,
        Sun: 0
      }
     
      var weekNumber,day,monthMoment;
      var year=new Date().getFullYear();
      var novdate=year+'-11-01';
      var dstenddate;

      // 1st Sunday of November .  
      weekNumber=1;
      day=days.Sun;
      monthMoment=moment(novdate);

      let m = monthMoment.clone()
      .startOf('month')                     
      .day(day)
       if (m.month() !== monthMoment.month()) 
      {
         m.add(7, 'd');
      }
      //dstenddate= m.add(7 * (weekNumber - 1), 'd').format('YYYY-MM-DDTHH:mm:ss.fff Z');
      dstenddate= m.add(7 * (weekNumber - 1), 'd');
      dstenddate=moment(dstenddate).add(2, 'hours');
      return dstenddate;        
       
    }
    //check daylight saving is active or not . 
    checkDateForDaylightSaving(date)
    {
       var DaylightStartDate= this.calculateDSTStartDate();
       var DaylightEndDate=this.calculateDSTEndDate();
       if(date >= DaylightStartDate && date <=DaylightEndDate)
       {
         return true;
       }
       else
       {
         return false;
       }
    }

    selectedtimezoneCalendarData(origevents,selectedtimezone,prevselectedtimezone) {
      var updtevents=origevents;
      if(prevselectedtimezone==="UTC" || prevselectedtimezone===null || prevselectedtimezone==='' || prevselectedtimezone===undefined)
      {
        if(selectedtimezone==="CST")
        {
          updtevents.forEach((eventlist) => {
          eventlist.start=this.convertUTCDateToCST(eventlist.start);
          eventlist.end=this.convertUTCDateToCST(eventlist.end);
          eventlist.Timezone=selectedtimezone;
        });

        }
        else if(selectedtimezone==="PST")
        {
          updtevents.forEach((eventlist) => {
            eventlist.start=this.convertUTCDateToPST(eventlist.start);
            eventlist.end=this.convertUTCDateToPST(eventlist.end);
            eventlist.Timezone=selectedtimezone;
          });
        }
      }
      else if(prevselectedtimezone==="CST")
      {
        if(selectedtimezone==="UTC")
        {
          updtevents.forEach((eventlist) => {
          eventlist.start=this.convertCSTDateToUTC(eventlist.start);
          eventlist.end=this.convertCSTDateToUTC(eventlist.end);
          eventlist.Timezone=selectedtimezone;
        });
        }
        else if(selectedtimezone==="PST")
        {
          updtevents.forEach((eventlist) => {
            eventlist.start=this.convertCSTDateToPST(eventlist.start);
            eventlist.end=this.convertCSTDateToPST(eventlist.end);
            eventlist.Timezone=selectedtimezone;
          });
        }
      }
      else if(prevselectedtimezone==="PST")
      {
        if(selectedtimezone==="UTC")
        {
          updtevents.forEach((eventlist) => {
          eventlist.start=this.convertPSTDateToUTC(eventlist.start);
          eventlist.end=this.convertPSTDateToUTC(eventlist.end);
          eventlist.Timezone=selectedtimezone;
        });
        }
        else if(selectedtimezone==="CST")
        {
          updtevents.forEach((eventlist) => {
            eventlist.start=this.convertPSTDateToCST(eventlist.start);
            eventlist.end=this.convertPSTDateToCST(eventlist.end);
            eventlist.Timezone=selectedtimezone;
          });
        }
      }
      return updtevents;
    }

     // this is function that is setting timezone to UTC
    setEventDataUTCTimezone(origevents) {
      origevents.forEach((eventlist) => {
        if(eventlist.Timezone==="PST" || eventlist.Timezone==="CST")
        {
          //eventlist.Timezone="UTC";
          //console.log('inside IF in setEventDataUTCTimezone method')
        }
      });
      return origevents;
    }

    async onSelectRegionData(value) {
      this.setState({
        selectedRegionData: value
      });
    }

    async onSelectTimezoneData(value) {
      this.setState({
        selectedTimezoneData: value
      });
    }

    formatShortDate(str1){
      const shortdate = moment(new Date(str1))
      return shortdate.format("YYYY-MM-DDTHH:mm:ss").toString();; 
    }

    formatDate(event) {
      let startdate = event.start;
      let enddate = event.end;
    
      if (event.Timezone === "PST") {
        startdate = Date.parse(new Date(this.convertUTCDateToPST(new Date(event.start))));
        startdate = moment(new Date(startdate), "YYYY-MM-DDTHH:mm:ss.fff Z");
        startdate = startdate.format("YYYY-MM-DDTHH:mm:ss");
        enddate = Date.parse(new Date(this.convertUTCDateToPST(new Date(event.end))));
        enddate = moment(new Date(enddate), "YYYY-MM-DDTHH:mm:ss.fff Z");
        enddate = enddate.format("YYYY-MM-DDTHH:mm:ss");
      } else if (event.Timezone === "CST") {
        startdate = Date.parse(new Date(this.convertUTCDateToCST(new Date(event.start))));
        startdate = moment(new Date(startdate), "YYYY-MM-DDTHH:mm:ss.fff Z");
        startdate = startdate.format("YYYY-MM-DDTHH:mm:ss");
        enddate = Date.parse(new Date(this.convertUTCDateToCST(new Date(event.end))));
        enddate = moment(new Date(enddate), "YYYY-MM-DDTHH:mm:ss.fff Z");
        enddate = enddate.format("YYYY-MM-DDTHH:mm:ss");
      }
      else if(event.Timezone === "UTC") {
        startdate = moment(new Date(startdate), "YYYY-MM-DDTHH:mm:ss.fff Z");
        startdate = startdate.format("YYYY-MM-DDTHH:mm:ss");
        enddate = moment(new Date(enddate), "YYYY-MM-DDTHH:mm:ss.fff Z");
        enddate = enddate.format("YYYY-MM-DDTHH:mm:ss");
      }
      return { startdate, enddate };
    }

  render(){ 
    const {currentDate, currentView,displayedDateRage,filteredevents} = this.state;
    
    if(this.state.ReturnStatus === 500)
    {
      return <InternalServerError/>;
    }
    if(this.state.emptyArray){   
    
    return <CustomSpinner label="Loading" />;
    } 
  
    return (
      
      <div className="render-container">
        <Filters 
          // passing events array to filters.js which is called in constructor
          events={this.state.events}
          filteredevents={this.state.filteredevents}
          newfilteredevents={this.state.newfilteredevents}
          newdate={this.updateSmallCalendar.bind(this)} 
          filters = {this.props.filters} 
          internalUser = {this.props.internalUser} 
          selectedTimezoneData={this.state.selectedTimezoneData}
          newevents={this.updateEvents.bind(this)}  
          role = {this.props.role}
          userEmail = {this.props.userEmail}  
          calendars={this.state.calendars}
          calendartypes={this.state.calendartypes} 
          selectedCalendar={this.state.selectedCalendar}
          userOrgName={this.props.userOrgName}  
        />

        <GetPersonaLegend 
          Persona = {this.props.role} 
          internalUser = {this.props.internalUser}
          selectedCalendar={this.state.selectedRadioButton}
          calendartypes={this.state.calendartypes} >
        </GetPersonaLegend>
  
        <div className="dg-container-test" style={{ width: '100%' }}>
            <EditModalPopUp
              show= {this.state.showModal}
              event = {this.state.event}
              closeModalPopUpFunc = {this.closeModalPopUp} 
              userEmail = {this.props.userEmail}
              role = {this.props.role}
              removeEvent = {this.handleRemoveEvent}  
              updateEvent = {this.handleUpdateEvent}     
            />
            <AddEventModalPopUp 
              show={this.state.showAddEventModal}  
              event = {this.state.event}
              submitModalPopUpFunc = {this.handleAddEventModalPopUp}
              userEmail = {this.props.userEmail}
              role = {this.props.role}
              addEvent = {this.handleAddEvent} 
            /> 
            <AddFactoryProductionEvent
              show={this.state.showAddEventModal}
              submitModalPopUpFunc = {this.handleAddEventModalPopUp} 
              userEmail = {this.props.userEmail}
              role = {this.props.role}  
              addEvent = {this.handleAddEvent}       
            />
            <EditFactoryProductionEvent
              show= {this.state.showModal}
              event = {this.state.event}
              closeModalPopUpFunc = {this.closeModalPopUp} 
              userEmail = {this.props.userEmail}
              role = {this.props.role}
              removeEvent = {this.handleRemoveEvent}  
              updateEvent = {this.handleUpdateEvent}        
            />
            <div className="title">    
              <PageTitle>
                SAMP Calendar
                <span slot="active-title">Events | {this.state.testparvalue}  </span> 
              </PageTitle>
              <div className="toolbar" style={{  }}>
                <NewToolbar handleAddEventModal={this.props.handleAddEventModalPopUpFunc} currrole={this.props.role} />
              </div>
            </div> 
            <div className="calendar-container"> 
              <div className="event-table">
                <table className="he-table he-table--fixed-heading" >
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th >Title</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Timezone</th>
                      <th>Type</th>
                      <th>Legend</th>
                    </tr>
                  </thead>
                  <tbody>
                  {
                    this.state.events.map((event) =>  ( 
                      <tr key={event.outageId}>
                        <td>{event.outageId}</td>
                        <td>{event.title}</td>
                        <td>{this.formatShortDate(this.formatDate(event).startdate.toString())}</td>
                        <td>{this.formatShortDate(this.formatDate(event).enddate.toString())}</td>
                        <td>{event.Timezone}</td>
                        <td>{event.outageType}</td>
                        <td>
                          <div style={{
                            width: '20px',
                            height: '20px',
                            borderRadius: '50%',
                            backgroundColor: this.eventPropGetter(event).style.backgroundColor // Use the background color returned by the eventPropGetter function
                          }}></div>
                        </td>
                      </tr>
                    ))
                  }
                  </tbody>
                </table> 
              </div> 
            </div>
        </div>
      </div>
    );
  }  
}

export function getTestparvalue() {
  return ReactCalendar.instance?.state.testparvalue || 'placeholder';
}

export default class BigCalendar extends React.Component{

  render(){
    return (
      <div className="rbc-calendar">
        <CalendarApp
          show={false}
          role = {this.props.role}
          userEmail = {this.props.userEmail}
          filters = {this.props.filters}
          internalUser = {this.props.internalUser}  
          userOrgName={this.props.userOrgName}          
          {...this.props}          
        />
      </div> 
    )
  }
}