import React, { Component } from "react";

class RadioButtons extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // gender : "" , // use this one if you don't wanna any default value for gender
      gender: "True" // we are using this state to store the value of the radio button and also use to display the active radio button
    };

    this.handleRadioChange = this.handleRadioChange.bind(this);  // we require access to the state of component so we have to bind our function 
  }

  // this function is called whenever you change the radion button 
  handleRadioChange(event) {
      // set the new value of checked radion button to state using setState function which is async funtion
    this.setState({
      gender: event.target.value
    });
  }


  render() {
    return (
      <div>
        <div check>
          <input
            type="radio"
            value="True" // this is te value which will be picked up after radio button change
            checked={this.state.gender === "True"} // when this is true it show the male radio button in checked 
            onChange={this.handleRadioChange} // whenever it changes from checked to uncheck or via-versa it goes to the handleRadioChange function
          />
          <span
           style={{ marginLeft: "5px" }} // inline style in reactjs 
          >True</span>
           <input
            type="radio"
            value="False"
            checked={this.state.gender === "False"}
            onChange={this.handleRadioChange} style={{ marginLeft: "5px" }}
          />
          <span style={{ marginLeft: "5px" }}>False</span>
        </div>
        
      </div>
    );
  }
}
export default RadioButtons;