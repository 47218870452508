import { UserAgentApplication } from 'msal'
import config from './config';
import React, { Component } from 'react';
import {appInsights} from './common/AppInsights/AppInsights';
import uuid from 'react-native-uuid';
import {SERVICE_NAME,SERVICE_OFFERING,COMPONENT_NAME,SERVICE_LINE,COMPONENT_ID} from "./common/Constants/Constants";

export const fetchToken = async () => {
    
  var userAgentApplication = new UserAgentApplication({
    auth: {
      clientId: `${window.env.CLIENTID}`,
      redirectUri: `${window.location.origin}`,
      navigateToLoginRequestUrl: false,
      postLogoutRedirectUri : `${window.location.origin}/Logout`,
      tokenRefreshUri: window.location.origin + '/auth.html'
  },  
  cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: true
  }
      }); 
      var scopesArray = [window.env.SCOPE]; 
      const tokenRequest = {
        scopes: scopesArray,
        //redirectUri: window.location.origin + '/auth.html'
      };
      const tokenRedirectRequest = {
        scopes: scopesArray,
        redirectUri: window.location.origin
      };
      try{
        var token = await userAgentApplication.acquireTokenSilent(tokenRequest);
        if(token != null || token != undefined)
        {
          return token.accessToken;
        }        
      }
      catch(error) 
      {
        var err = {
          "message": "Exception occured in acquireTokenSilent method while fetching an access token and acquireTokenSilent failed", 
          "ComponentId" : COMPONENT_ID,
          "ComponentName" : COMPONENT_NAME,
          "EnvironmentName" : window.env.ENVIRONMENT,
          "ServiceLineName" : SERVICE_LINE,
          "ServiceName" : SERVICE_NAME,
          "ServiceOfferingName" : SERVICE_OFFERING,
          "exception": error.message,
          "Correlation Id": uuid.v1(),
         }               
         appInsights.trackException({exception: error,properties : err});
         return userAgentApplication.acquireTokenRedirect(tokenRedirectRequest); 
      }    
   }

  export const fetchRoles = (props) =>
  {
    switch(props) {
      case "SCE-Outagehub-Reader":
        return "Reader";
        
      case "SCE-Outagehub-Approver":
        return "Approver";
        
      case "SCE-Outagehub-BusinessCommunicator":
        return "BusinessUser";
        
      case "SCE-Outagehub-BusinessCommunicator SAMP_ChannelManagers":
          return "BusinessUser ChannelManagers";
          
      case "SCE-Outagehub-BusinessCommunicator SAMP_SAPUser":
          return "BusinessUser SAP";
          
      case "SCE-Outagehub-Writer":
        return "Writer";
        
      case "SCE-Outagehub-Admin":
        return "Admin";
        
      case "NotFound":
          return "NotFound";
          
      case "SCE-Outagehub-BusinessCommunicator SAMP_SAPReader":
          return "BusinessUser SAPReader";
            
      default:
        return "default";
    }
  } 

  export const getDomainName = (value) =>
  {
    if(value)
    {
      var name   = value.substring(0, value.lastIndexOf("@"));
      var domain = value.substring(value.lastIndexOf("@") +1);
      if(domain.toLowerCase() === "microsoft.com" || domain.toLowerCase() === "pegatroncorp.com")
      {
        return true;
      } 
      else
      return false;
    }
    else
     return false;
  
  }

  export const getValidApproverRole = (value) =>
  {
    if(value)
    {
      if(value.toLowerCase() === "approver" || value.toLowerCase() === "admin")
      {
        return true;
      } 
      else
      return false;
    }
    else
     return false;  
  }

  export const getValidRolePendingReqTab = (value) =>
  {
    if(value)
    {
      if(value.toLowerCase() === "approver" || value.toLowerCase() === "admin" || value.toLowerCase()==='writer')
      {
        return true;
      } 
      else
      return false;
    }
    else
     return false;  
  }

  export const getValidAdminRole = (value) =>
  {
    if(value)
    {
      if( value.toLowerCase() === "admin")
      {
        return true;
      } 
      else
      return false;
    }
    else
     return false;  
  }

  export async function fetchData(url, headers, componentName = "COMPONENT_NAME") {
    try {
      const response = await fetch(url, { method: "GET", headers });
      const statusCode = response.status;

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${statusCode}`);
      }

      const data = await response.json();
      return { statusCode, data };
    } catch (error) {
      const err = {
        message: `Exception occurred while getting the ${componentName}`,
        exception: error.message,
      };
      appInsights.trackException({ exception: error, properties: err });
      return { statusCode: 500, data: [] };
    }
  }